import React from 'react'
import Modal from "react-modal";
import './index.css'


function Loading (){
  return (
    <Modal isOpen contentLabel="My dialog" ariaHideApp={false} className="popup" portalClassName="modal" style={{
			overlay: {
			  position: 'fixed',
			  zIndex: 1043,
			  top: 0,
			  left: 0,
			  width: '100%',
			  height: '100%',
			  display: 'flex',
			  alignItems: 'center',
			  justifyContent: 'center',
			  backgroundColor: "rgba(0,0,0,0.5)"
			  
			//   -webkit-backface-visibility: hidden;
			}}}>
                <div className="loading loading--full-height">
				<span className="loader"></span>              
				    </div> 
              
         
        
        </Modal> 
  )
}

export default Loading