import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import CarouselDisplay from "../../../Components/Carousel";
import Header from "../../../Components/Header";
import "./index.css";
import { useGeolocated } from "react-geolocated";
import { defaultStorageHelper } from "@spry/campaign-client";
import { campaignClient, getSessionClient } from "../../../api";
import { endOfWeek } from "date-fns";
import Loading from "../../../Components/Loading";
import { startOfDay } from "date-fns/esm";
import ReactModal from "react-modal";
import "./index.css";
import { isLocationWithinBoundary } from "../../../helpers/utils";

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };
export type Matchup = {
  league: string;
  homeTeam: string;
  awayTeam: string;
  startDate: string;
  startTime: string;
};

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [matchups, setMatchups] = useState<Matchup[]>([]);
  const [timeLeft, setTimeLeft] = useState<{
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  } | null>();
  const [submitted, setSubmitted] = useState(false);
  const [firstname, setFirstName] = useState("");
  const [ballots, setBallots] = useState<{ monthly: number; grand: number }>();
  const [freezDate, setFreezeDate] = useState("");
  const [inBoundary, setInBoundary] = useState(false);
  const { positionError, getPosition } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    geolocationProvider: navigator.geolocation,
    userDecisionTimeout: 5000,
    suppressLocationOnMount: true,
    onSuccess: (data) => {
      defaultStorageHelper.set("latitude", `${data.coords.latitude}`);
      defaultStorageHelper.set("longitude", `${data.coords.longitude}`);
      const pointLat = data.coords.latitude;
      const pointLong = data.coords.longitude;
      console.log(isLocationWithinBoundary(pointLat, pointLong));

      if (isLocationWithinBoundary(pointLat, pointLong)) {
        setInBoundary(true);
      } else {
        window.vex.dialog.alert({
          unsafeMessage: `Sorry! You are out of boundary!`,
          callback: () => {
            // handleLogout()
            return;
          },
        });
      }
    },
    onError: (e) => {
      console.log("error:", e);
    },
  });
  console.log(freezDate)

  useEffect(() => {
    if (
      !defaultStorageHelper.get("latitude") ||
      !defaultStorageHelper.get("longitude")
    ) {
      setModalOpen(true);
    }
  }, []);

  const navigate = useNavigate();

  const handleLogout = async () => {
    defaultStorageHelper.remove("auth");
    defaultStorageHelper.remove("dateOfBirth");
    defaultStorageHelper.remove("longitude");
    defaultStorageHelper.remove("latitude");
    const sessionClient = getSessionClient();
    await sessionClient.call("logout", {
      sessionKey: sessionClient.sessionKey,
    });

    navigate("/");
  };

  const getplaybets = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const { sessionKey } = await getSessionClient();
      const {
        contest,
        currentweekSubmission,
        firstName,
        error,
        message,
        monthlyprizeballots,
        grandprizeballots,
      } = await campaignClient.call<{
        contest: any[];
        currentweekSubmission: boolean;
        firstName: string;
        monthlyprizeballots: number;
        grandprizeballots: number;
        error?: string;
        message?: string;
      }>("getmatchups", { sessionKey });
      setSubmitted(currentweekSubmission);
      if (error === "EXPIRED") {
        window.vex.dialog.alert({
          unsafeMessage: `${message}`,
          callback: () => {
            handleLogout();
            return;
          },
        });
      }
      if (contest) {
        setMatchups(contest[0].matchups);
        defaultStorageHelper.set("selectedWeek", contest[0].contestWeek);
        let currentWeekFreezDate = startOfDay(
          endOfWeek(new Date(contest[0].startDate))
        );
        // console.log(currentWeekFreezDate,differenceInDays(new Date(currentWeekFreezDate),new Date()))
        setFreezeDate(currentWeekFreezDate.toISOString());
        //  setDaysLeft(differenceInDays(new Date(currentWeekFreezDate),new Date()));
        setFirstName(firstName);
        setBallots({ monthly: monthlyprizeballots, grand: grandprizeballots });
      }
    } catch (e) {}
    setLoading(false);
  };
  console.log(timeLeft);

  function calculateTimeLeft(comparedate: Date) {
    let timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    const difference = +comparedate - +new Date();

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
      return timeLeft;
    }
  }
  useEffect(() => {
    setTimeout(() => {
      if (freezDate) {
        setTimeLeft(calculateTimeLeft(new Date(freezDate)));
      }
    }, 1000);
  }, [freezDate]);

  useEffect(() => {
    getplaybets();
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Header />
      <div className="main dashboard">
        <div className="main__background">
          <img
            src="assets/images/temp/background-1@2x.jpg"
            alt=""
            width="1920"
            height="857"
            className="hidden-xs"
          />
          <img
            src="assets/images/temp/background-1-mobile@2x.jpg"
            alt=""
            width="1920"
            height="857"
            className="visible-xs-block"
          />
        </div>
        {/* /.main__background */}

        <div className="main__inner">
          <div className="section-alt section-features">
            <div className="shell shell--small">
              <div className="section__inner">
                <header className="section__head">
                  <div className="section__image">
                    <img
                      src="assets/images/svg/prop-bet.svg"
                      alt=""
                      width="340"
                      height="96"
                    />
                  </div>
                  {/* /.section__image */}
                </header>
                {/* /.section__head */}

                <div className="section__body">
                  <h1>hi {firstname}, let’s play!</h1>

                  <p>
                    Follow all with this weekends games to see if you get your
                    prop picks correct! All games are shown below.
                  </p>

                  <div className="section__body-inner">
                    {/* <CarouselDisplay/> */}
                    <div className="slider slider--tiles js-slider">
                      <div className="slider__clip">
                        <div className="slider__slides">
                          <CarouselDisplay matchups={matchups || []} />
                        </div>
                        {/* /.slider__slides */}
                      </div>
                      {/* /.slider__clip */}
                    </div>
                    {/* /.slider js-slider */}

                    {submitted ? (
                      <div className="tile-alt">
                        <div className="tile__inner">
                          <div className="tile__icon">
                            <i className="ico-check">
                              <img
                                src="assets/images/svg/check-large.svg"
                                alt=""
                                width="44"
                                height="44"
                              />
                            </i>
                          </div>
                          {/* /.tile__icon */}

                          <div className="tile__content">
                            <p>
                              Your picks are locked in for the week, Good luck!
                              Check your{" "}
                              <button
                                style={{
                                  background: "transparent",
                                  textDecoration: "underline",
                                  border: "none",
                                }}
                                onClick={() => {
                                  navigate("/history");
                                }}
                              >
                                history
                              </button>{" "}
                              each Monday to see how you did.
                            </p>
                          </div>
                          {/* /.tile__content */}
                        </div>
                        {/* /.tile__inner */}
                      </div>
                    ) : (
                      <div className="">
                        {freezDate && new Date() < new Date(freezDate) ? (
                          <>
                            {timeLeft && timeLeft?.days > 0 && (
                              <p
                                style={{
                                  textAlign: "center",
                                  textTransform: "uppercase",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#8cd471",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {timeLeft?.days} days{" "}
                                </span>{" "}
                                left to play this week’s props
                              </p>
                            )}
                            <div className="form__foot play-btn">
                              <button
                                className=" btn btn--success btn--block form__btn "
                                onClick={() => {
                                  navigate("/propbets");
                                }}
                                // disabled={
                                //   (!positionError && inBoundary) ? false : true
                                // }
                              >
                                play this week’s props
                              </button>
                              {!inBoundary && <span className="tooltiptext"> <i className="fas fa-exclamation-circle" />{" "} you are out of boundary!</span>}
                            </div>
                          </>
                        ) : (
                          <button
                            className="btn btn--success btn--block form__btn"
                            onClick={() => {
                              navigate("/propbets");
                            }}
                            disabled
                          >
                            play this week’s props
                          </button>
                        )}

                        {/* /.tile__content */}
                        {/* /.tile__inner */}
                      </div>
                    )}
                    {/* /.tile-alt */}
                  </div>
                  {/* /.section__body-inner */}

                  <div className="features">
                    <div className="feature features__feature">
                      <h1>{ballots?.monthly}</h1>

                      <h6>Monthly Prize draw ballots</h6>
                    </div>
                    {/* /.feature features__feature */}

                    <div className="feature features__feature">
                      <h1>{ballots?.grand}</h1>

                      <h6>Grand prize draw ballots</h6>
                    </div>
                    {/* /.feature features__feature */}
                  </div>
                  {/* /.features */}
                </div>
                {/* /.section__body */}
              </div>
              {/* /.section__inner */}
            </div>
            {/* /.shell */}
          </div>
          {/* /.section-features */}
        </div>
        {/* /.main__inner */}
      </div>
      {/* /.main */}
      <ReactModal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        className="dashboard-modal"
        overlayClassName="Overlay"
      >
        <>
          <p>"propbetfaceoff.com" would like to use your current location. </p>
          <div className="modal-actions">
            <button
              className="modal-btn"
              onClick={() => {
                setModalOpen(false);
                window.vex.dialog.alert({
                  unsafeMessage: `You have to share your location inorder to play!`,
                  callback: () => {
                    setModalOpen(true);
                    return;
                  },
                });
              }}
            >
              Don't Allow
            </button>
            <button
              className="modal-btn"
              onClick={() => {
                setModalOpen(false);
                getPosition();
              }}
            >
              Allow
            </button>
          </div>
        </>
      </ReactModal>
    </>
  );
};

export default Dashboard;
