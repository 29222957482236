import React from "react";
import { useNavigate } from "react-router";
import Header from "../../../Components/Header";
import ReactPlayer from "react-player";
import "./index.css";
// import ReactPlayer from "react-player";

const Playbook = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <div className="main playbook">
        <div className="main__background">
          <img
            src="assets/images/temp/background-1@2x.jpg"
            alt=""
            width="1920"
            height="857"
            className="hidden-xs"
          />
          <img
            src="assets/images/temp/background-1-mobile@2x.jpg"
            alt=""
            width="1920"
            height="857"
            className="visible-xs-block"
          />
        </div>
        {/* /.main__background */}

        <div className="main__inner">
          <section className="section-alt section-alt--secondary">
            <div className="shell shell--small">
              <header className="section__head">
                <div className="section__image">
                  <img
                    src="assets/images/svg/prop-bet.svg"
                    alt=""
                    width="340"
                    height="96"
                  />
                </div>
                {/* /.section__image */}
              </header>
              {/* /.section__head */}

              <div className="section__body">
                <div className="form__head">
                  <h1>Bet playbook</h1>
                  <p>Learn the ins and outs of PROLINE+ bets.</p>
                </div>

                {/* /.form__foot */}

                <div className="section-points">
                  <h4>Money line</h4>
                  <div className="section-video">
                    <ReactPlayer
                      id="player"
                      className="videoPlayer"
                      url={"https://vimeo.com/819187413?share=copy"}
                      onPlay={() => {}}
                      onEnded={() => {}}
                      controls={true}
                      config={{
                        file: {
                          attributes: {
                            controlsList: "nodownload",
                            onContextMenu: (e: any) => e.preventDefault(),
                          },
                        },
                      }}
                    />
                  </div>
                  <p>
                    Moneyline markets are simply about picking which
                    team will win the game between an underdog and a favourite.
                  </p>
                  <h6>MONEYLINE (MATCH)</h6>
                  <div className="radios">
                                  <ul>
                                    
                                      <li > 
                                        <div className="radio">
                                          <input
                                            type="radio"
                                            disabled                                          
                                            value="BUFFALO"
                                          />

                                          <label >
                                          BUFFALOw

                                            <span>1.35</span>
                                          </label>
                                        </div>
                                        {/* /.radio */}
                                      </li>
                                      <li > 
                                        <div className="radio">
                                          <input
                                            type="radio"
                                            disabled                                          
                                            value="DETROIT"
                                          />

                                          <label >
                                          DETRPOIT

                                            <span>3.3</span>
                                          </label>
                                        </div>
                                        {/* /.radio */}
                                      </li>
                                      
                                    
                                  </ul>
                                 

                                </div>
                  <p>
                    In this example, the odds tell us Detroit is the underdog
                    and Buffalo is the favourite. Making your Moneyline bet is
                    as simple as selecting whichever team you think will win,
                    outright, without spreads or totals.
                  </p>
                  <p>
                    Bets on the favourite payout less if they win since that is
                    the expected outcome. Bets on the underdog payout much more
                    if they win since that outcome is less likely.
                  </p>

                  <h4>Over/under (Totals)</h4>
                  <div className="section-video">
                    <ReactPlayer
                      id="player"
                      className="videoPlayer"
                      url={"https://vimeo.com/819188968?share=copy"}
                      onPlay={() => {}}
                      onEnded={() => {}}
                      controls={true}
                      config={{
                        file: {
                          attributes: {
                            controlsList: "nodownload",
                            onContextMenu: (e: any) => e.preventDefault(),
                          },
                        },
                      }}
                    />
                  </div>
                  <p>
                  An Over/Under bet is a prediction on whether the total number of runs scored in a game will go over or under a set total—which is why you’ll sometimes see them called “Total” bets.
                  </p>
                  <h6>OVER/UNDER (MATCH)</h6>
                  <div className="radios">
                                  <ul>
                                    
                                      <li > 
                                        <div className="radio">
                                          <input
                                            type="radio"
                                            disabled                                          
                                            value="BUFFALO"
                                          />

                                          <label >
                                          OVER 50.5

                                            <span>1.91</span>
                                          </label>
                                        </div>
                                        {/* /.radio */}
                                      </li>
                                      <li > 
                                        <div className="radio">
                                          <input
                                            type="radio"
                                            disabled                                          
                                            value="DETROIT"
                                          />

                                          <label >
                                          under 50.5

                                            <span>1.91</span>
                                          </label>
                                        </div>
                                        {/* /.radio */}
                                      </li>
                                      
                                    
                                  </ul>
                                 

                                </div>
                                <p>In the example above, you’re betting on whether the combined total score of both teams would add up to over or under 50.5. In this example, the odds are even, meaning each outcome is as likely as the other.</p>
                  <h4>Prop bets</h4>
                  <div className="section-video">
                    <ReactPlayer
                      id="player"
                      className="videoPlayer"
                      url={"https://vimeo.com/819191397?share=copy"}
                      onPlay={() => {}}
                      onEnded={() => {}}
                      controls={true}
                      config={{
                        file: {
                          attributes: {
                            controlsList: "nodownload",
                            onContextMenu: (e: any) => e.preventDefault(),
                          },
                        },
                      }}
                    />
                  </div>
                  <p>
                  Prop Bets or “props” are a type of market for specific outcomes in a game (“game props”) or outcomes related to a player’s performance (“player props”).
                  </p>
                  <p>
                  Will one of the teams score a home run? Which player will score the first run of the game? Both are examples of Prop Bets.
                  </p>
                  <h6>WILL THERE BE OVERTIME?</h6>
                  <div className="radios">
                                  <ul>
                                    
                                      <li > 
                                        <div className="radio">
                                          <input
                                            type="radio"
                                            disabled                                          
                                            value="BUFFALO"
                                          />

                                          <label >
                                         YES

                                            <span>3.2</span>
                                          </label>
                                        </div>
                                        {/* /.radio */}
                                      </li>
                                      <li > 
                                        <div className="radio">
                                          <input
                                            type="radio"
                                            disabled                                          
                                            value="DETROIT"
                                          />

                                          <label >
                                          NO

                                            <span>1.33</span>
                                          </label>
                                        </div>
                                        {/* /.radio */}
                                      </li>
                                      
                                    
                                  </ul>
                                 

                                </div>
                  <h4>Point spread</h4>
                  <div className="section-video">
                    <ReactPlayer
                      id="player"
                      className="videoPlayer"
                      url={"https://vimeo.com/819190448?share=copy"}
                      onPlay={() => {}}
                      onEnded={() => {}}
                      controls={true}
                      config={{
                        file: {
                          attributes: {
                            controlsList: "nodownload",
                            onContextMenu: (e: any) => e.preventDefault(),
                          },
                        },
                      }}
                    />
                  </div>

                  <p>
                  A bet on whether one team’s score will beat or cover an assigned point handicap or margin.

                  </p>
                  <h6>POINT SPREAD (MATCH)</h6>
                  <div className="radios">
                                  <ul>
                                    
                                      <li > 
                                        <div className="radio">
                                          <input
                                            type="radio"
                                            disabled                                          
                                            value="BUFFALO"
                                          />

                                          <label >
                                         BUFFALO -6.5

                                            <span>1.91</span>
                                          </label>
                                        </div>
                                        {/* /.radio */}
                                      </li>
                                      <li > 
                                        <div className="radio">
                                          <input
                                            type="radio"
                                            disabled                                          
                                            value="DETROIT"
                                          />

                                          <label >
                                          DETROIT +6.5

                                            <span>1.91</span>
                                          </label>
                                        </div>
                                        {/* /.radio */}
                                      </li>
                                      
                                    
                                  </ul>
                                 

                                </div>

                  <p>
                  Here’s an example of a Point Spread market. The Point Spread is the number beside the team’s name, in this case 6.5:</p><p>
Detroit is +6.5—if they lose by less than 7 points (or win the game), they win the point spread bet.
Buffalo is -6.5—if they win by more than 7 points, they win the Point Spread bet.
                  </p>
                </div>
                <div className="form__foot">
                  <button
                    className="btn btn--success btn--block form__btn"
                    onClick={() => navigate("/dashboard")}
                  >
                    I’m ready to play
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Playbook;
