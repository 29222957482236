import React from "react";
import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./index.css";
import { format } from "date-fns";

export type Matchup = {
  league: string;
  homeTeam: string;
  awayTeam: string;
  startDate: string;
  startTime: string;
};

const CarouselDisplay = ({ matchups }: { matchups: Matchup[] }) => {
  // console.log(matchups);

  return (
    <Carousel showThumbs={false}>
      {matchups.length
        ? matchups.map((l) => (
            <div className="slider__slide" key={l.homeTeam+l.startDate}>
              <div className="tile-main">
                <div className="tile__inner">
                  <h3>{l.league}</h3>

                  <h5>
                    {l.homeTeam}
                    <br />@ {l.awayTeam}
                  </h5>

                  <ul>
                    <li>
                      {format(new Date(l.startDate), "EEEE MMMM dd , yyyy")}
                    </li>

                    <li>{format(new Date(l.startTime), "hh:mm a")}</li>
                  </ul>
                </div>
                {/* /.tile__inner */}
              </div>
              {/* /.tile-main */}
            </div>
          ))
        : [
            <div className="slider__slide" key="d">
              <div className="tile-main">
                <div className="tile__inner">
                  No matchups found. Please come back later.
                </div>
              </div>
            </div>,
          ]}
    </Carousel>
  );
};

export default CarouselDisplay;
