import { Route, Navigate } from "react-router-dom";
import Closed from "../Pages/Closed";
import ComingSoon from "../Pages/ComingSoon";
import Login from "../Pages/Open/Login";
import Open from "../Pages/Open";
import Instructions from "../Pages/Open/Instructions";
import Landing from "../Pages/Open/Landing";
import Playbook from "../Pages/Open/Playbook";
import Registration from "../Pages/Open/Registration";
import Splash from "../Pages/Open/Splash";
import Declartion from "../Pages/Open/Declaration";
import Claimed from "../Pages/Open/Claimed";
import ForgotPassword from "../Pages/Open/Forgot";
import ResetPassword from "../Pages/Open/ResetPassword";


export default (
  <>
    <Route path="/" element={<Open />}>
    <Route path="/" element={<Landing />} />
      <Route path="splash" element={<Splash />} />
      <Route path="instructions" element={<Instructions />} />
      <Route path="betplaybook" element={<Playbook />} />
      <Route path="register" element={<Registration />} />
      <Route path="login" element={<Login />} />
      <Route path="forgotpassword" element={<ForgotPassword />} />
      <Route path="resetPassword" element={<ResetPassword />} />
      <Route path="declaration/:sk/:pk" element={<Declartion/>} />
      <Route path="/claimed" element={<Claimed/>} />
      <Route path="*" element={<Navigate to="/" />} />
    </Route>
    <Route path="closed" element={<Closed />} />
    <Route path="coming" element={<ComingSoon />} />
  </>
);
