import { defaultStorageHelper } from '@spry/campaign-client'
import { nextDay } from 'date-fns'
import React from 'react'
import { useNavigate } from 'react-router'
import Header from '../../../Components/Header'
import './index.css'

const Confirmation = () => {
	const navigate = useNavigate()
	const selectedWeek = defaultStorageHelper.get("selectedWeek")
	let resultDate 
	if(selectedWeek){
	 let parsedDate = selectedWeek.split('#')[3].replace('-', '/')
	 resultDate =nextDay(new Date(parsedDate),1)

	}
  return (
    <>
    <Header/>
    <div className="main confirmation">
			<div className="main__background">
				<img src="assets/images/temp/background-1@2x.jpg" alt="" width="1920" height="857" className="hidden-xs" />
				<img src="assets/images/temp/background-1-mobile@2x.jpg" alt="" width="1920" height="857" className="visible-xs-block" />
			</div>{/* /.main__background */}

			<div className="main__inner">
				<div className="section-alt section-features--alt">
					<div className="shell shell--small">
						<div className="section__inner">
							<header className="section__head">
								<div className="section__image">
									<img src="assets/images/svg/prop-bet.svg" alt="" width="340" height="96" />
								</div>{/* /.section__image */}
							</header>{/* /.section__head */}

							<div className="section__body">
										<div className="form__head">
											<h1>your picks are in!</h1>
                                            <div className="tile__icon">
												<i className="ico-check">
													<img src="assets/images/svg/check-green.svg" alt="" width="64" height="64" />
												</i>
											</div>{/* /.tile__icon */}
                                        </div>
                                        <div className="form__body">
                                            <p>
                                            Thanks for making your picks for the week. The weekly winners will be announced on {resultDate ? new Date(resultDate).toLocaleDateString() :"next Monday"}
                                            </p>

                                        </div>
                                        <div className="form__foot">
											<button type="submit" className="btn btn--success btn--block" onClick={()=>navigate('/')}>Back to my dashboard</button>
										</div>{/* /.form__foot */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
  )
}

export default Confirmation