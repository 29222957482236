import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
  emailRegex,
  passwordRegex,
  stringRegex,
} from "../../../helpers/regexes";
import { useEffect, useRef, useState } from "react";
import {
  campaignClient,
  getSessionClient,
  setSessionClient,
} from "../../../api";
import Header from "../../../Components/Header";
import { RegisterArgs } from "../../../helpers/types";
import "./index.css";
import Loading from "../../../Components/Loading";
import { defaultStorageHelper } from "@spry/campaign-client";

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

export default function Registration() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    getFieldState,
    watch,
    formState: { errors },
  } = useForm<RegisterArgs>({ mode: "onTouched" });

  const [submitting, setSubmitting] = useState(false);
  const [type, setType] = useState("password");

  const hanldeViewPassword = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  const hanldeHidePassword = () => {
    document
      .getElementById("campaignPassword")
      ?.setAttribute("type", "password");
  };

  useEffect(() => {
   if(!defaultStorageHelper.get('dateOfBirth')){
    navigate("/");

   }
   
  }, [])
  

  async function handleRegister(data: RegisterArgs) {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    try {
      const { sessionKey, error, message } = await getSessionClient()
        .createParticipantBuilder()
        .setFirstName(data.firstName)
        .setLastName(data.lastName)
        .setEmail(data.email)
        .addMetadata({ ...data.metadata,dateOfBirth:defaultStorageHelper.get('dateOfBirth') })
        .call<{ sessionKey?: string; error?: string; message?: string }>(
          "register"
        );
      if (sessionKey) {
        setSessionClient(campaignClient.recoverSession(sessionKey));
        defaultStorageHelper.set('auth',"true")

        navigate("/betplaybook");
      } else if (error) {
        window.vex.dialog.alert({
          unsafeMessage: `${message}`,
          callback: () => {
            return;
          },
        });
      }
    } catch {
      window.vex.dialog.alert({
        unsafeMessage: `Sorry, something went wrong. Please try again later.`,
        callback: () => {
          return;
        },
      });
    }
    setSubmitting(false);
  }

  return (
    <>
      {submitting && <Loading/>}
      <Header />
      <div className="main">
        <div className="main__background">
          <img
            src="assets/images/temp/background-1@2x.jpg"
            alt=""
            width="1920"
            height="857"
            className="hidden-xs"
          />
          <img
            src="assets/images/temp/background-1-mobile@2x.jpg"
            alt=""
            width="1920"
            height="857"
            className="visible-xs-block"
          />
        </div>
        {/* /.main__background */}

        <div className="main__inner">
          <section className="section-alt section-alt--secondary">
            <div className="shell shell--small">
              <header className="section__head">
                <div className="section__image">
                  <img
                    src="assets/images/svg/prop-bet.svg"
                    alt=""
                    width="340"
                    height="96"
                  />
                </div>
                {/* /.section__image */}
              </header>
              {/* /.section__head */}

              <div className="section__body">
                <div className="form form--registration">
                  <form onSubmit={handleSubmit(handleRegister)}>
                    <div className="form__head">
                      <h1>ENTER YOUR INFO</h1>

                      <p>* Required field.</p>
                    </div>
                    {/* /.form__head */}

                    <div className="form__body">
                      <div className="form__row">
                        <label
                          htmlFor="field-first-name"
                          className="form__label"
                        >
                          * First name
                        </label>

                        <div className="form__controls">
                          <input
                            type="text"
                            className="field"
                            {...register("firstName", {
                              required: {
                                value: true,
                                message: "Please enter your first name.",
                              },
                              pattern: {
                                value: stringRegex,
                                message: "Please enter a valid first name.",
                              },
                            })}
                            placeholder=""
                          />
                        </div>
                        {/* /.form__controls */}
                      </div>
                      {/* /.form__row */}
                      {errors.firstName && (
                        <p className="error-message">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.firstName.message}{" "}
                        </p>
                      )}

                      <div className="form__row">
                        <label
                          htmlFor="field-last-name"
                          className="form__label"
                        >
                          * Last name
                        </label>

                        <div className="form__controls">
                          <input
                            type="text"
                            className="field"
                            {...register("lastName", {
                              required: {
                                value: true,
                                message: "Please enter your last name.",
                              },
                              pattern: {
                                value: stringRegex,
                                message: "Please enter a valid last name.",
                              },
                            })}
                            placeholder=""
                          />
                        </div>
                        {/* /.form__controls */}
                      </div>
                      {/* /.form__row */}
                      {errors.lastName && (
                        <p className="error-message">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.lastName.message}{" "}
                        </p>
                      )}

                      <div className="form__row">
                        <label htmlFor="field-email" className="form__label">
                          * email
                        </label>

                        <div className="form__controls">
                          <input
                            type="text"
                            className="field"
                            {...register("email", {
                              required: {
                                value: true,
                                message: "Please enter your email.",
                              },
                              pattern: {
                                value: emailRegex,
                                message: "Please enter a valid email.",
                              },
                            })}
                            placeholder=""
                          />
                        </div>
                        {/* /.form__controls */}
                      </div>
                      {/* /.form__row */}
                      {errors.email && (
                        <p className="error-message">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.email.message}{" "}
                        </p>
                      )}

                      <div className="form__row passwordControl">
                        <label htmlFor="field-password" className="form__label">
                          * password
                        </label>

                        <div className="form__controls">
                          <button
                            className="viewPass"
                            type="button"
                            onClick={hanldeViewPassword}
                            onMouseUp={hanldeHidePassword}
                            disabled={
                              getValues().metadata?.password.length > 0
                                ? false
                                : true
                            }
                          >
                            <img
                              src="assets/images/svg/ico-view.png"
                              alt="View Password"
                            />
                          </button>
                          <input
                            type={type}
                            id="campaignPassword"
                            className="field"
                            {...register("metadata.password", {
                              required: {
                                value: true,
                                message: "Please enter a password.",
                              },
                              pattern: {
                                value: passwordRegex,
                                message: "Please enter a valid password.",
                              },
                            })}
                            placeholder=""
                          />
                          <small>
                            (password must be between 8 to 20 characters, must contain at least 1 uppercase, 1 lowercase and 1 number.)
                          </small>
                        </div>
                        {/* /.form__controls */}
                      </div>
                      {/* /.form__row */}
                      {errors.metadata?.password && (
                        <p className="error-message">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.metadata.password.message}{" "}
                        </p>
                      )}

                      <div className="form__row">
                        <label
                          htmlFor="field-confirm-password"
                          className="form__label"
                        >
                          * Confirm password
                        </label>

                        <div className="form__controls">
                          <input
                            type="password"
                            className="field"
                            placeholder=""
                            {...register("confirmPassword", {
                              required: {
                                value: true,
                                message: "Please confirm your password.",
                              },
                              validate: (val: string) => {
                                if (watch("metadata.password") != val) {
                                  return "Please make sure your passwords match.";
                                }
                              },
                            })}
                          />
                        </div>
                        {/* /.form__controls */}
                      </div>
                      {errors.confirmPassword && (
                        <p className="error-message">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.confirmPassword.message}{" "}
                        </p>
                      )}
                      {/* /.form__row */}

                      <div className="checkboxes form__checkboxes">
                        <ul>
                          <li>
                            <div className="checkbox">
                              <input
                                type="checkbox"
                                {...register("metadata.rules", {
                                  required: {
                                    value: true,
                                    message:
                                      "You must agree to the Terms & Conditions to register.",
                                  },
                                })}
                                id="field-checkbox-1"
                              />

                              <label htmlFor="field-checkbox-1">
                                * I agree to the{" "}
                                <a rel="noreferrer" href="https://www.olg.ca/en/legal/player-agreement.html" target="_blank">Terms & Conditions</a>.
                              </label>
                            </div>
                            {errors.metadata?.rules && (
                              <p className="error-message">
                                <i className="fas fa-exclamation-circle" />{" "}
                                {errors.metadata.rules.message}{" "}
                              </p>
                            )}
                            {/* /.checkbox */}
                          </li>

                          <li>
                            <div className="checkbox">
                              <input
                                type="checkbox"
                                {...register("metadata.optin1")}
                                id="field-checkbox-2"
                              />

                              <label htmlFor="field-checkbox-2">
                                I would like to receive OLG communications and
                                offers. You can withdraw your consent at any
                                time. Visit our <a rel="noreferrer" href="https://www.olg.ca/en/privacy-and-cookie-policy.html" target="_blank">
                                  Privacy Policy
                                </a>{" "}
                                for details.
                              </label>
                            </div>
                            {/* /.checkbox */}
                          </li>
                        </ul>
                      </div>
                      {/* /.checkboxes */}
                    </div>
                    {/* /.form__body */}

                    <div className="form__foot">
                      <button className="btn btn--success btn--block form__btn">
                        submit
                      </button>

                      <h4>
                        Already have an account? <a href="/login">Log in</a>
                      </h4>
                    </div>
                    {/* /.form__foot */}
                  </form>
                </div>
                {/* /.form form-/-date */}
              </div>
              {/* /.section__body */}
            </div>
            {/* /.shell-/-small */}
          </section>
          {/* /.section-alt */}
        </div>
        {/* /.main__inner */}
      </div>
      {/* /.main */}
    </>
  );
}
