import { defaultStorageHelper } from "@spry/campaign-client";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getSessionClient } from "../../api";
import './index.css'
import { toast } from "../../Components/Toast/ToastManager";
const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const authed = defaultStorageHelper.get("auth");
  const longitude = defaultStorageHelper.get("longitude")
  const lattitude = defaultStorageHelper.get("latitude") 

  const handleLogout = async () => {
    defaultStorageHelper.remove("auth");
    defaultStorageHelper.remove("dateOfBirth");
    defaultStorageHelper.remove("longitude");
    defaultStorageHelper.remove("latitude");
    const sessionClient = getSessionClient();
    setMenuOpen(false)
       await sessionClient.call("logout", { sessionKey:sessionClient.sessionKey});
       toast.show({
        content: "Logged out successfully.",
        duration: 3000,
        error: false,
      });
    
    navigate('/')
  };

  return (
    <>
      <header className="header">
        <div className="shell">
          <div className="header__inner">
            <a href="/" className="logo header__logo">
              <img
                src="assets/images/svg/logo.svg"
                alt="Logo"
                width="138"
                height="30"
              />
            </a>

            <a
              onClick={() =>
                menuOpen ? setMenuOpen(false) : setMenuOpen(true)
              }
              className={
                menuOpen
                  ? "nav-trigger js-nav-trigger is-active"
                  : "nav-trigger js-nav-trigger"
              }
            >
              <span></span>

              <span></span>

              <span></span>
            </a>
          </div>
          {/* /.header__inner */}

          <nav
            className={
              menuOpen ? "nav header__nav is-active" : "nav header__nav"
            }
          >
            <ul>
              <li>
                <Link to="/" onClick={()=>setMenuOpen(!menuOpen)}>Dashboard</Link>
              </li>
              {(longitude && lattitude)&&
              <li>
                <Link to="/propbets" onClick={()=>setMenuOpen(!menuOpen)}>PLAY THIS WEEK’S PROPS</Link>
              </li>
}

              <li>
                <Link to="/history" onClick={()=>setMenuOpen(!menuOpen)}> History</Link>
                {/* <Link href="/history">History</a> */}
              </li>

              <li>
                <Link to="/instructions" onClick={()=>setMenuOpen(!menuOpen)}>How does it work?</Link>
              </li>

              <li>
                <Link to="/betplaybook" onClick={()=>setMenuOpen(!menuOpen)}>Bet playbook</Link>
              </li>

              <li>
              <a href="https://www.olg.ca/en/legal/player-agreement.html" rel="noreferrer" target="_blank">CONTEST RULES</a>

              </li>

              <li>
                <a href="https://www.olg.ca/en/privacy-and-cookie-policy.html" rel="noreferrer" target="_blank">privacy policy</a>
              </li>

              <li>
                {authed ?
                <button
                  style={{ background: "transparent", border: "none" }}
                  onClick={handleLogout}
                  type="button"
                >
                  log out
                </button>
                : 
                <button
                style={{ background: "transparent", border: "none" }}
                onClick={()=>navigate('login')}
                type="button"
              >
                log in
              </button>}
              </li>
            </ul>
          </nav>
          {/* /.nav */}
        </div>
        {/* /.shell */}
      </header>
      {/* /.header */}
    </>
  );
};

export default Header;
