import React from "react";
import { useNavigate } from "react-router";
import Header from "../../../Components/Header";
import './index.css'
const Instructions = () => {

  const navigate = useNavigate();
  return (
    <>
      <Header />
      <div className="main">
        <div className="main__background">
          <img
            src="assets/images/temp/background-1@2x.jpg"
            alt=""
            width="1920"
            height="857"
            className="hidden-xs"
          />
          <img
            src="assets/images/temp/background-1-mobile@2x.jpg"
            alt=""
            width="1920"
            height="857"
            className="visible-xs-block"
          />
        </div>
        {/* /.main__background */}

        <div className="main__inner">
          <section className="section-alt section-alt--secondary">
            <div className="shell shell--small">
              <header className="section__head">
                <div className="section__image">
                  <img
                    src="assets/images/svg/prop-bet.svg"
                    alt=""
                    width="340"
                    height="96"
                  />
                </div>
                {/* /.section__image */}
              </header>
              <div className="section__body">
                <div className="form__head">
                  <h1>How does it work?</h1>
                </div>
                <div className="insturctions-list">
                  <ol>
                    <li>
                      Make your 15 prop picks for the week. The first 10 are
                      props you would find on PROLINE+ and the other 5 fun props
                      you can try with your friends anytime you watch a game.
                    </li>
                    <li>
                      Watch this weekends featured games and follow along with
                      your picks to see how well you do. Each correct pick will
                      earn you one ballot into all prize draws!
                    </li>
                    <li>
                      A weekly winner will be selected at the start of each
                      week. A monthly winner at the start of each month. And
                      finally, the Grand Prize winner of $25,000 will be
                      announced at the end of the contest period!
                    </li>
                  </ol>
                </div>
                <div className="form__foot">
											<button type="submit" className="btn btn--success btn--block" onClick={()=>{navigate('/register')}}>Next</button>
										</div>{/* /.form__foot */}
                {/* /.form__head */}
              </div>
              {/* /.section__head */}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Instructions;
