import { Route, Navigate } from "react-router-dom";
import Admin from "../Pages/Admin";
import Closed from "../Pages/Closed";
import ComingSoon from "../Pages/ComingSoon";
import Login from "../Pages/Open/Login";
import Logout from "../Pages/Open/Logout";
import Open from "../Pages/Open";
import FanMail from "../Pages/Open/FanMail";
import InstantWin from "../Pages/Open/InstantWin";
import Landing from "../Pages/Open/Landing";
import Registration from "../Pages/Open/Registration";
import Returning from "../Pages/Open/Returning";
import StuffToDo from "../Pages/Open/StuffToDo";
import Upload from "../Pages/Open/Upload";
import RedemptionOnly from "../Pages/RedemptionOnly";
import Suspended from "../Pages/Suspended";
import Version from "../Pages/Version";
import AdminLanding from "../Pages/Admin/Landing";
import "../i18n";
import Splash from "../Pages/Open/Splash";
import Instructions from "../Pages/Open/Instructions";
import Playbook from "../Pages/Open/Playbook";
import Dashboard from "../Pages/Open/Dashboard";
import Propbets from "../Pages/Open/Propbets";
import Confirmation from "../Pages/Open/Confirmation";
import History from "../Pages/Open/History";
import Rules from "../Pages/Rules";
import Declaration from '../Pages/Open/Declaration'
import Claimed from "../Pages/Open/Claimed";

export default (
  <>
  
    <Route path="/" element={<Open />}>
      <Route path="/" element={<Dashboard />} />
      <Route path="betplaybook" element={<Playbook />} />
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="instructions" element={<Instructions />} />
      <Route path="propbets" element={<Propbets />} />
      <Route path="confirmation" element={<Confirmation />} />
      <Route path="returning" element={<Returning />} />
      <Route path="history" element={<History />} />
      <Route path="declaration/:sk/:pk" element={<Declaration/>} />
      <Route path="claimed" element={<Claimed/>} />
      <Route path="*" element={<Navigate to="/" />} />

    </Route>
    <Route path="admin" element={<Admin />}>
      <Route path="/admin" element={<AdminLanding />} />
    </Route>
    <Route path="rules" element={<Rules />} />

    <Route path="closed" element={<Closed />} />
    <Route path="coming" element={<ComingSoon />} />
    <Route path="redemption" element={<RedemptionOnly />} />
    <Route path="suspended" element={<Suspended />} />
    <Route path="login" element={<Login />} />
    <Route path="logout" element={<Logout />} />
    <Route path="spry/version" element={<Version />} />
  </>
);
