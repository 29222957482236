import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./index.css";
import * as Yup from "yup";
import { isValid, parse,differenceInYears } from "date-fns";
import { defaultStorageHelper } from "@spry/campaign-client";

type BirthdayForm = {
  day: number;
  month: number;
  year: number;
};

export default function Landing() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [day, setDay] = useState<string>("");
  const [month, setMonth] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [error, setError] = useState("");

  const handlebirthday = async () => {
    if (year.length < 4 || +year > new Date().getFullYear()) {
      setError("Please enter a valid year");
      return;
    }
    
    const parsedate = parse(
      `${day}.${month}.${year}`,
      "dd.MM.yyyy",
      new Date()
    );
    const dateOfBirth = isValid(parsedate);
    if (dateOfBirth) {

      if(differenceInYears(new Date(),new Date(parsedate))>18){
        setError("");
        defaultStorageHelper.set(
          "dateOfBirth",
          new Date(parsedate).toISOString()
        );
      navigate('/splash')

      }
      else{
        setError("Sorry, you must be at least 19 years old to register.")
        return
      }
      
    } else {
      setError("Please enter a valid date of birth.");
      return;
    }
  };

  return (
    <>

      <div className="main">
        <div className="main__background">
          <img
            src="assets/images/temp/background-1@2x.jpg"
            alt=""
            width="1920"
            height="857"
            className="hidden-xs"
          />
          <img
            src="assets/images/temp/background-1-mobile@2x.jpg"
            alt=""
            width="1920"
            height="857"
            className="visible-xs-block"
          />
        </div>
        {/* /.main__background */}

        <div className="main__inner">
          <section className="section-alt">
            <div className="shell shell--small">
              <header className="section__head">
                <a href="/" className="logo section__logo">
                  <img
                    src="assets/images/svg/logo.svg"
                    alt="Logo"
                    width="164"
                    height="35"
                  />
                </a>

                <div className="section__image">
                  <img
                    src="assets/images/svg/prop-bet.svg"
                    alt=""
                    width="340"
                    height="96"
                  />
                </div>
                {/* /.section__image */}
              </header>
              {/* /.section__head */}

              <div className="section__body">
                <div className="form form--date">
                  <form action="?" method="post">
                    <div className="form__head">
                      <h1>please enter your date of birth</h1>
                    </div>
                    {/* /.form__head */}

                    <div className="form__body">
                      <div className="grid form__grid">
                        <div className="grid__col grid__col--1of3">
                          <div className="form__row">
                            <label htmlFor="field-day" className="form__label">
                              day
                            </label>

                            <div className="form__controls">
                              <input
                                type="number"
                                className="field field--center"
                                placeholder="DD"
                                value={day}
                                onWheel={(event) => event.currentTarget.blur()}
                                onChange={(e) => {
                                  //setDay(+e.currentTarget.value)
                                  let twodigit = e.currentTarget.value.slice(
                                    0,
                                    2
                                  );
                                  if (e.currentTarget.value.length > 2) {
                                    setDay(twodigit);
                                  } else {
                                    setDay(e.currentTarget.value);
                                  }
                                }}
                              />
                            </div>
                            {/* /.form__controls */}
                          </div>
                          {/* /.form__row */}
                        </div>
                        {/* /.grid__col grid__col-/-1of3 */}

                        <div className="grid__col grid__col--1of3">
                          <div className="form__row">
                            <label
                              htmlFor="field-month"
                              className="form__label"
                            >
                              MONTH
                            </label>

                            <div className="form__controls">
                              <input
                                type="number"
                                className="field field--center"
                                placeholder="MM"
                                onWheel={(event) => event.currentTarget.blur()}
                                value={month}
                                onChange={(e) => {
                                  //setDay(+e.currentTarget.value)
                                  let twodigit = e.currentTarget.value.slice(
                                    0,
                                    2
                                  );
                                  if (e.currentTarget.value.length > 2) {
                                    setMonth(twodigit);
                                  } else {
                                    setMonth(e.currentTarget.value);
                                  }
                                }}
                              />
                            </div>
                            {/* /.form__controls */}
                          </div>
                          {/* /.form__row */}
                        </div>
                        {/* /.grid__col grid__col-/-1of3 */}

                        <div className="grid__col grid__col--1of3">
                          <div className="form__row">
                            <label htmlFor="field-year" className="form__label">
                              YEAR
                            </label>

                            <div className="form__controls">
                              <input
                                type="number"
                                className="field field--center"
                                placeholder="YYYY"
                                onWheel={(event) => event.currentTarget.blur()}
                                value={year}
                                onChange={(e) => {
                                  //setDay(+e.currentTarget.value)
                                  let twodigit = e.currentTarget.value.slice(
                                    0,
                                    4
                                  );
                                  if (e.currentTarget.value.length > 2) {
                                    setYear(twodigit);
                                  } else {
                                    setYear(e.currentTarget.value);
                                  }
                                }}
                              />
                            </div>
                            {/* /.form__controls */}
                          </div>
                          {/* /.form__row */}
                        </div>
                        {/* /.grid__col grid__col-/-1of3 */}
                      </div>
                      {/* /.grid */}
                    </div>
                    {/* /.form__body */}
                    {error && (
                      <p className="error-message">
                        <i className="fas fa-exclamation-circle" /> {error}{" "}
                      </p>
                    )}

                    <div className="form__foot">
                      <button
                        className="btn btn--success btn--block"
                        type="button"
                        onClick={handlebirthday}
                        disabled={!day || !month || !year ? true : false}
                      >
                        submit
                      </button>
                    </div>
                    {/* /.form__foot */}
                  </form>
                </div>
                {/* /.form form-/-date */}
              </div>
              {/* /.section__body */}
            </div>
            {/* /.shell-/-small */}
          </section>
          {/* /.section-alt */}
        </div>
        {/* /.main__inner */}
      </div>
      {/* /.main */}
    </>
  );
}
