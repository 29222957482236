import React from 'react'

const Splash = () => {
  return (
    <>
    <div className="main">
			<div className="main__background">
				<img src="assets/images/temp/background-1@2x.jpg" alt="" width="1920" height="857" className="hidden-xs" />
				<img src="assets/images/temp/background-1-mobile@2x.jpg" alt="" width="1920" height="857" className="visible-xs-block" />
			</div>{/* /.main__background */}

			<div className="main__inner">
				<div className="section-alt section-alt--primary">
					<div className="shell shell--small">
						<div className="section__inner">
							<header className="section__head">
								<a href="#" className="logo section__logo">
									<img src="assets/images/svg/logo.svg" alt="Logo" width="164" height="35" />
								</a>

								<div className="section__image">
									<img src="assets/images/svg/prop-bet.svg" alt="" width="340" height="96" />
								</div>{/* /.section__image */}
							</header>{/* /.section__head */}

							<div className="section__body">
								<div className="section__images-group">
									<img src="assets/images/svg/image-1.svg" alt="" width="340" height="252" />

									<img src="assets/images/temp/image-2@2x.png" alt="" width="340" height="183" />
								</div>{/* /.images-group */}
							</div>{/* /.section__body */}

							<footer className="section__foot">
								<ul className="list--buttons section__list--buttons">
									<li>
										<a href="/instructions" className="btn btn--success btn--block">enter now</a>
									</li>

									<li>
										<a href="/login" className="btn btn--outline-success btn--block">login</a>
									</li>
								</ul>{/* /.list-/-buttons */}
							</footer>{/* /.section__foot */}
						</div>{/* /.section__inner */}
					</div>{/* /.shell */}
				</div>{/* /.section-alt */}
			</div>{/* /.main__inner */}
		</div>{/* /.main */}
    </>
  )
}

export default Splash