import { useState } from "react";
import { campaignClient, getSessionClient, setSessionClient } from "../../../api";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Header from "../../../Components/Header";
import { emailRegex } from "../../../helpers/regexes";
import { defaultStorageHelper } from "@spry/campaign-client";
import Loading from "../../../Components/Loading";

type LoginData = {
  email: string;
  password: string;
};

export default function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginData>();

  const [loggingIn, setLoggingIn] = useState(false);
  const [authFailed, setAuthFailed] = useState(false);
  const [authError, setAuthError] = useState("");
  const [forgetPassword,setForget]=useState(false)

  async function handleLogin(data: LoginData) {
    setLoggingIn(true);
    setAuthError("");
    setAuthFailed(false);
    try {
      const sessionClient = getSessionClient();
      const { authed,sessionKey,error,message } = await sessionClient.call<{
        authed: boolean;
        sessionKey?: string;
        error?:string;
        message?:string;
      }>("login", { email: data.email, password: data.password ,sessionKey:sessionClient.sessionKey});
      if (!authed && message) {
        setAuthFailed(true);
        setAuthError("We couldn’t find an account matching the email and password you entered. Please check your email and password and try again.")
      } else {
        setSessionClient(campaignClient.recoverSession(sessionKey));
        defaultStorageHelper.set("auth", "true");

        navigate("/betplaybook");
      }
    } catch (e: any) {
      setAuthError(e.message);
    }
    setLoggingIn(false);
  }

  return (
    <>
        {loggingIn && <Loading/>}

      <Header />

      <div className="main">
        <div className="main__inner">
          <section className="section-alt section-login">
            <div className="shell shell--small">
              <div className="section__image" style={{ marginBottom: "6rem" }}>
                <img
                  src="assets/images/svg/prop-bet.svg"
                  alt=""
                  width="340"
                  height="96"
                />
              </div>

              <div className="section__body">
                <div className="form form--alt form--login">
                  <form onSubmit={handleSubmit(handleLogin)}>
                    <div className="form__head">
                      <h1>login</h1>
                    </div>
                    {/* /.form__head */}

                    <div className="form__body">
                      <div className="form__row">
                        <label className="form__label form__label--alt">
                          Email
                        </label>

                        <div className="form__controls">
                          <input
                            type="text"
                            className="field "
                            {...register("email", {
                              required: {
                                value: true,
                                message: "Please enter your email.",
                              },
                              pattern: {
                                value: emailRegex,
                                message: "Please enter a valid email.",
                              },
                            })}
                          />
                        </div>
                        {errors.email && (
                        <p className="error-message">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.email.message}{" "}
                        </p>
                      )}
                        {/* /.form__controls */}
                      </div>
                     
                      {/* /.form__row */}
                      <div className="form__row">
                        <label
                          htmlFor="field-password"
                          className="form__label form__label--alt"
                        >
                          Password
                        </label>

                        <div className="form__controls">
                          <input
                            type="password"
                            className="field"
                            {...register("password", {
                              required: {
                                value: true,
                                message: "Please enter your password.",
                              },
                            })}
                          />
                        </div>
                        {/* /.form__controls */}
                        {errors.password && (
                        <p className="error-message">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.password.message}{" "}
                        </p>
                      )}
                      </div>
                      {/* /.form__row */}

                      <button className="link-btn" type="button" onClick={()=>navigate('/forgotpassword')}> Forgot password?</button>
                    </div>
                    {/* /.form__body */}

                    <div className="form__foot">
                      <button className="btn btn--success btn--block">
                        Login
                      </button>
                    </div>
                    {authError&& <p className="error-message">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {authError}{" "}
                        </p> }
                    {/* /.form__foot */}
                  </form>
                </div>
                {/* /.form form-/-date */}
              </div>
              {/* /.section__body */}
            </div>
            {/* /.shell-/-small */}
          </section>
          {/* /.section-alt */}
        </div>
        {/* /.main__inner */}
      </div>
    </>
  );
}
