import { defaultStorageHelper } from "@spry/campaign-client";
import { useEffect,useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { getSessionClient } from "./api";
import loadGtm from "./data/loadGtm";
import CustomRoutes from "./Config/Routes";
import BaseRoutes from "./Config/BaseRoutes";
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Pages from "./Pages";


type LocationReport = { path: string; hash?: string; search?: string };
let lastLocationReport: LocationReport = { path: "" };

export default function App() {
  const location = useLocation();
  const [route,setRoute]=useState<boolean|null>(null)

  async function reportLocation(currentLocation: LocationReport) {
    await getSessionClient()
      .createFlowBuilder()
      .setTag("navigation")
      .addMetadata(currentLocation)
      .commit();
  }

  useEffect(() => {
    loadGtm();
  }, []);

  useEffect(() => {
    const currentLocation: LocationReport = { path: location.pathname };
    if (location.hash && location.hash !== "#") {
      currentLocation.hash = location.hash;
    }
    if (location.search && location.search !== "?") {
      currentLocation.search = location.search;
    }
    if (
      lastLocationReport.path !== currentLocation.path ||
      lastLocationReport.hash !== currentLocation.hash ||
      lastLocationReport.search !== currentLocation.search
    ) {
      reportLocation(currentLocation);
      lastLocationReport = currentLocation;
    }
  });
  const auth = defaultStorageHelper.get('auth')

 

 useEffect(() => {
  if(auth){
    setRoute(true)
  } 
  else{
    setRoute(null)
  }
 }, [auth])
 
 

  return route ? (
    <Routes>
    <Route path="/" element={<Pages />}>
      {CustomRoutes}
    </Route>
    </Routes>
  ) : (
    <Routes>
    <Route path="/" element={<Pages />}>

    {BaseRoutes}
    </Route>
    </Routes>

  );
}
