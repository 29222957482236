export function b64toBlob(
  b64Data: string,
  contentType: string,
  sliceSize = 512
) {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function isLocationWithinBoundary(
  pointLat: number,
  pointLong: number,
) {
  /*
   * Check if a given latitude and longitude point is within a geographic boundary
   * defined by four latitude and longitude points.
   */
  const boundary = [
    [43.64273145574055, -79.38191272155619],
    [43.64303425100863, -79.38022561214584],
    [43.64150861315577, -79.38107319016535],
    [43.64215303488259, -79.37953896666339],
  ];
  
  // Determine the minimum and maximum latitudes and longitudes of the boundary
  const lats = boundary.map((point) => point[0]);
  const longs = boundary.map((point) => point[1]);
  const minLat = Math.min(...lats);
  const maxLat = Math.max(...lats);
  const minLong = Math.min(...longs);
  const maxLong = Math.max(...longs);

  // Check if the point is within the defined boundary
  if (
    pointLat >= minLat &&
    pointLat <= maxLat &&
    pointLong >= minLong &&
    pointLong <= maxLong
  ) {
    return true;
  } else {
    return false;
  }
}

// Example usage

; // true

export enum CONTEST_STATUS {
  Future = 1, //new contest for future week
  OnGoing = 2, //currently on going contest
  Pending = 3, //passed contest week but not confirm results
  InProgressing = 4, //confirmed result and draw winner in progress
  Closed = 5, //winners been draw
}
