import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Header from "../../../Components/Header";
import Loading from "../../../Components/Loading";
import {
  canadianPostalCodeRegex,
  looseNorthAmericanPhoneRegex,
  stringRegex,
  stringRegexwithspace,
} from "../../../helpers/regexes";
import { Modal } from "react-bootstrap";
import SignaturePad from "react-signature-canvas";
import { b64toBlob } from "../../../helpers/utils";
import {
  campaignClient,
  getSessionClient,
  setSessionClient,
} from "../../../api";
import axios from "axios";
import "./index.css";
import { defaultStorageHelper } from "@spry/campaign-client";
import { useNavigate, useParams } from "react-router";

type DeclarationForm = {
  legalName: string;
  address1: string;
  address2: string;
  city: string;
  province: string;
  postal: string;
  phoneNumber: string;
};
declare const window: Window &
  typeof globalThis & {
    vex: any;
  };
const Declaration = () => {
  
  const navigate = useNavigate();
  const { sk,pk } = useParams();

  

  useEffect(() => {
    // if(!sk || !pk){
    //   window.vex.dialog.alert({
    //     unsafeMessage: `Invalid link.`,
    //     callback: () => {
    //       navigate("/");
    //       return;
    //     },
    //   });
    // }

    checkClaim()

  }, [sk,pk])

  const [submitting, setSubmitting] = useState(false);
  const [showsignature, setShowSignature] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const signCanvas = React.useRef() as React.MutableRefObject<any>;
  const clear = () => signCanvas.current.clear();
  const [signature, setSignature] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadingPercent, setUploadingPercent] = useState(0);
  const [uploadError, setUploadError] = useState(false);
  const [duplicateFile, setDuplicateFile] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    getFieldState,
    watch,
    formState: { errors },
  } = useForm<DeclarationForm>({ mode: "onTouched" });

 

  const checkClaim = async () => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    try {
      const { sessionKey } = getSessionClient();
      const { claimed, error , message} = await campaignClient.call<{
        error?: string;
        message?: string;
        claimed?: boolean;
      }>("checkclaim", {
        sessionKey:sk,
        prizeWinnerKey:pk
        
      });
      
      if (error) {
        window.vex.dialog.alert({
          unsafeMessage: `${message}`,
          callback: () => {
            navigate("/");
            return;
          },
        });
      } else {
        setSessionClient(campaignClient.recoverSession(sessionKey));
        setSubmitting(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeclare = async (data: DeclarationForm) => {
    if (submitting || !imageURL) {
      return;
    }
    setSubmitting(true);
    try {
      const { sessionKey } = getSessionClient();
      const { claimed, error, message } = await campaignClient.call<{
        claimed: boolean;
        error: string;
        message: string;
      }>("prizeClaim", {
        sessionKey,
        prizeWinnerKey:pk,
        ...data,
      });
      if (claimed) {
        navigate("/claimed");
      } else if (error) {
        window.vex.dialog.alert({
          unsafeMessage: `${message}`,
          callback: () => {
            navigate("/");
            return;
          },
        });
      }
    } catch (e) {
      window.vex.dialog.alert({
        unsafeMessage: `Something went wrong , please try later.`,
        callback: () => {
          navigate("/");
          return;
        },
      });
    }

    // console.log(data);
    setSubmitting(false);
  };

  const getfileuploadurl = async (sessionKey: string, type: string) => {
    try {
      const { uploadUrl } = await campaignClient.call<{ uploadUrl: string }>(
        "getFileUploadUrlForUser",
        {
          sessionKey,
          filename: "signature",
          contentType: ".jpg",
        }
      );
      return uploadUrl;
    } catch (e) {
      console.log(e);
    }
  };
  const saveSignature = async (sign: any) => {
    const sessionClient = getSessionClient();
    if (uploading) {
      return;
    }
    setUploading(true);
    defaultStorageHelper.set("signature", sign);

    const urltoupload = await getfileuploadurl(
      sessionClient.sessionKey,
      typeof sign
    );

    if (sign && urltoupload) {

      const block = sign.split(";");
      const contentType = block[0].split(":")[1];
      const realData = block[1].split(",")[1];
      const blob = b64toBlob(realData, contentType);
      try {
        const data = await blob.arrayBuffer();
        axios
          .put(urltoupload, data, {
            onUploadProgress: function (progressEvent) {
              // const percentCompleted = Math.round(
              //   (progressEvent.loaded * 100) / progressEvent.total
              // );
            },
            headers: {
              "Content-Type": typeof blob,
            },
          })
          .then(async (res) => {
            if (res.status == 200) {
              setImageURL(sign);
              setShowSignature(false);
            }
          })
          .catch((e) => {
            setSubmitting(false);
            console.log(e);
          });

        setUploadComplete(true);
      } catch {
        setUploadError(true);
      }
      setUploading(false);
    }
  };

  return (
    <>
    <div className="declaration">

      {submitting && <Loading />}
      <Header />

      <div className="main">
        <div className="main__background">
          <img
            src="assets/images/temp/background-1@2x.jpg"
            alt=""
            width="1920"
            height="857"
            className="hidden-xs"
          />
          <img
            src="assets/images/temp/background-1-mobile@2x.jpg"
            alt=""
            width="1920"
            height="857"
            className="visible-xs-block"
          />
        </div>
        {/* /.main__background */}

        <div className="main__inner">
          <div className="section-alt section-features">
            <div className="shell shell--small">
              <div className="section__inner">
                <header className="section__head">
                  <div className="section__image">
                    <img
                      src="assets/images/svg/prop-bet.svg"
                      alt=""
                      width="340"
                      height="96"
                    />
                  </div>
                  {/* /.section__image */}
                </header>
                {/* /.section__head */}

                <div className="section__body">
                  {/* <a className="js-popup js-popup--open" href="assets/ajax/popup.html"></a> */}

                  <div className="form form--declaration">
                    <form onSubmit={handleSubmit(handleDeclare)}>
                      <div className="form__head">
                        <h1>DECLARATION AND RELEASE </h1>
                      </div>
                      {/* /.form__head */}

                      <div className="form__body">
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Morbi leo dui, pellentesque non ipsum sodales,
                          efficitur viverra leo. Sed feugiat neque ac massa
                          commodo, eget consectetur nulla placerat. Phasellus
                          gravida, nulla at elementum viverra, justo risus
                          vehicula massa, nec hendrerit risus nibh vitae elit.
                          Proin dictum feugiat justo. Proin id enim a nulla
                          dignissim aliquam. Sed sollicitudin tincidunt
                          vehicula. Mauris mauris lacus, tincidunt vel efficitur
                          sed, volutpat sed ante. Integer sit amet dolor
                          molestie, consectetur eros bibendum, finibus mauris.
                        </p>

                        <span className="form__asterisk">
                          * Required field.
                        </span>

                        <div className="form__group">
                          <h4>I do hereby state and declare that I</h4>

                          <div className="form__row">
                            <label
                              htmlFor="field-legal-name"
                              className="form__label"
                            >
                              * Legal name
                            </label>

                            <div className="form__controls">
                              <input
                                type="text"
                                className="field"
                                {...register("legalName", {
                                  required: {
                                    value: true,
                                    message: "Please enter your legal name.",
                                  },
                                  pattern: {
                                    value: stringRegexwithspace,
                                    message: "Please enter a valid legal name.",
                                  },
                                })}
                                placeholder=""
                              />
                            </div>
                            {/* /.form__controls */}
                            {errors.legalName && (
                              <p className="error-message">
                                <i className="fas fa-exclamation-circle" />{" "}
                                {errors.legalName.message}{" "}
                              </p>
                            )}
                          </div>
                          {/* /.form__row */}
                        </div>
                        {/* /.form__group */}

                        <div className="form__group">
                          <h4>of (Full Mailing Address)</h4>

                          <div className="form__row">
                            <label
                              htmlFor="field-address-1"
                              className="form__label"
                            >
                              * Address Line 1
                            </label>

                            <div className="form__controls">
                              <input
                                type="text"
                                className="field"
                                {...register("address1", {
                                  required: {
                                    value: true,
                                    message:
                                      "Please enter your address line 1.",
                                  },
                                })}
                                placeholder=""
                              />
                            </div>
                            {/* /.form__controls */}
                            {errors.address1 && (
                              <p className="error-message">
                                <i className="fas fa-exclamation-circle" />{" "}
                                {errors.address1.message}{" "}
                              </p>
                            )}
                          </div>
                          {/* /.form__row */}

                          <div className="form__row">
                            <label
                              htmlFor="field-address-2"
                              className="form__label"
                            >
                              * Address Line 2
                            </label>

                            <div className="form__controls">
                              <input
                                type="text"
                                className="field"
                                {...register("address2")}
                                placeholder=""
                              />
                            </div>
                            {/* /.form__controls */}
                            {errors.address2 && (
                              <p className="error-message">
                                <i className="fas fa-exclamation-circle" />{" "}
                                {errors.address2.message}{" "}
                              </p>
                            )}
                          </div>
                          {/* /.form__row */}

                          <div className="form__row">
                            <label htmlFor="field-city" className="form__label">
                              * City
                            </label>

                            <div className="form__controls">
                              <input
                                type="text"
                                className="field"
                                {...register("city", {
                                  required: {
                                    value: true,
                                    message: "Please enter your city.",
                                  },
                                })}
                                placeholder=""
                              />
                            </div>
                            {/* /.form__controls */}
                            {errors.city && (
                              <p className="error-message">
                                <i className="fas fa-exclamation-circle" />{" "}
                                {errors.city.message}{" "}
                              </p>
                            )}
                          </div>
                          {/* /.form__row */}

                          <div className="form__row">
                            <label
                              htmlFor="field-select-1"
                              className="form__label"
                            >
                              * Province
                            </label>

                            <div className="form__controls">
                              <div className="select">
                                <select
                                  id="field-select-1"
                                  {...register("province", {
                                    required: {
                                      value: true,
                                      message: "Please select a province.",
                                    },
                                  })}
                                >
                                  <option value="">
                                    Please select an option
                                  </option>
                                  <option value="AB">Alberta</option>
                                  <option value="BC">British Columbia</option>
                                  <option value="MB">Manitoba</option>
                                  <option value="NB">New Brunswick</option>
                                  <option value="NL">
                                    Newfoundland and Labrador
                                  </option>
                                  <option value="NS">Nova Scotia</option>
                                  <option value="NT">
                                    Northwest Territories
                                  </option>
                                  <option value="NU">Nunavut</option>
                                  <option value="ON">Ontario</option>
                                  <option value="PE">
                                    Prince Edward Island
                                  </option>
                                  <option value="QC">Quebec</option>
                                  <option value="SK">Saskatchewan</option>
                                  <option value="YT">Yukon</option>
                                </select>
                              </div>
                              {/* /.select */}
                            </div>
                            {/* /.form__controls */}
                            {errors.province && (
                              <p className="error-message">
                                <i className="fas fa-exclamation-circle" />{" "}
                                {errors.province.message}{" "}
                              </p>
                            )}
                          </div>
                          {/* /.form__row */}

                          <div className="form__row">
                            <label
                              htmlFor="field-postal-code"
                              className="form__label"
                            >
                              * Postal code
                            </label>

                            <div className="form__controls">
                              <input
                                type="text"
                                className="field"
                                {...register("postal", {
                                  required: {
                                    value: true,
                                    message: "Please enter a postal code.",
                                  },
                                  pattern: {
                                    value: canadianPostalCodeRegex,
                                    message:
                                      "Please enter a valid postal code.",
                                  },
                                })}
                                placeholder=""
                              />
                            </div>
                            {/* /.form__controls */}
                            {errors.postal && (
                              <p className="error-message">
                                <i className="fas fa-exclamation-circle" />{" "}
                                {errors.postal.message}{" "}
                              </p>
                            )}
                          </div>
                          {/* /.form__row */}

                          <div className="form__row">
                            <label
                              htmlFor="field-phone-number"
                              className="form__label"
                            >
                              * phone number
                            </label>

                            <div className="form__controls">
                              <input
                                type="text"
                                className="field"
                                {...register("phoneNumber", {
                                  required: {
                                    value: true,
                                    message: "Please enter a phone number.",
                                  },
                                  pattern: {
                                    value: looseNorthAmericanPhoneRegex,
                                    message:
                                      "Please enter a valid phone number..",
                                  },
                                })}
                                placeholder=""
                              />
                            </div>
                            {/* /.form__controls */}
                          </div>
                          {errors.phoneNumber && (
                            <p className="error-message">
                              <i className="fas fa-exclamation-circle" />{" "}
                              {errors.phoneNumber.message}{" "}
                            </p>
                          )}
                          {/* /.form__row */}
                        </div>
                        {/* /.form__group */}

                        <div className="form__group">
                          <h4>
                            am the selected Prize winner in the Contest (the
                            “Winner”).{" "}
                          </h4>

                          <div className="form__entry">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Morbi leo dui, pellentesque non ipsum
                              sodales, efficitur viverra leo. Sed feugiat neque
                              ac massa commodo, eget consectetur nulla placerat.
                              Phasellus gravida, nulla at elementum viverra,
                              justo risus vehicula massa, nec hendrerit risus
                              nibh vitae elit. Proin dictum feugiat justo. Proin
                              id enim a nulla dignissim aliquam. Sed
                              sollicitudin tincidunt vehicula. Mauris mauris
                              lacus, tincidunt vel efficitur sed, volutpat sed
                              ante. Integer sit amet dolor molestie, consectetur
                              eros bibendum, finibus mauris.
                            </p>

                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Morbi leo dui, pellentesque non ipsum
                              sodales, efficitur viverra leo. Sed feugiat neque
                              ac massa commodo, eget consectetur nulla placerat.
                              Phasellus gravida, nulla at elementum viverra,
                              justo risus vehicula massa, nec hendrerit risus
                              nibh vitae elit. Proin dictum feugiat justo. Proin
                              id enim a nulla dignissim aliquam. Sed
                              sollicitudin tincidunt vehicula. Mauris mauris
                              lacus, tincidunt vel efficitur sed, volutpat sed
                              ante. Integer sit amet dolor molestie, consectetur
                              eros bibendum, finibus mauris.
                            </p>
                          </div>
                          {/* /.form__entry */}
                        </div>
                      </div>
                      {/* /.form__body */}

                      <div className="form__foot">
                        <ul className="list--buttons section__list--buttons">
                          <li>
                            {imageURL ? (
                                <>
                              <div className="signature-display">
                                <img src={imageURL} alt="signature" />
                              </div>
                              <button
                              className="btn btn--outline-success btn--block"
                              onClick={() => {
                                setImageURL(null);
                              }}
                            >
                              Clear Signature
                            </button>
                            </>
                            ) : (
                              <button
                                className="btn btn--outline-success btn--block"
                                onClick={() => {
                                  setShowSignature(true);
                                }}
                              >
                                Add signature
                              </button>
                            )}
                            {!imageURL && (
                              <p className="error-message">
                                <i className="fas fa-exclamation-circle" />{" "}
                                Please add your signature
                              </p>
                            )}
                          </li>

                          <li>
                            <button
                              type="submit"
                              className="btn btn--success btn--block"
                              disabled={imageURL ? false : true}
                            >
                              Submit
                            </button>
                          </li>
                        </ul>
                        {/* /.list-/-buttons */}
                      </div>

                      {/* /.form__foot */}
                    </form>
                  </div>
                  {/* /.form form-/-declaration */}
                </div>
                {/* /.section__body */}
              </div>
              {/* /.section__inner */}
            </div>
            {/* /.shell */}
          </div>
          {/* /.section-features */}
        </div>
        {/* /.main__inner */}
      </div>
      {/* /.main */}

      <Modal show={showsignature} onHide={() => {}} centered 
                className="dec-modal">

<Modal.Body style={{ padding: "20px" }}>
          <div className="popup popup--alt">
            <div className="popup__inner">
              <div className="form form--main">
                <div className="form__head">
                  <h4>ADD A DIGITAL SIGNATURE</h4>
                </div>
                {/* /.form__head */}

                <div className="" style={{ marginBottom: "4rem" }}>
                  {uploading && <Loading />}
                  <SignaturePad
                    ref={signCanvas}
                    
                    canvasProps={{
                      className: "signPad",
                      style: {
                        position: "relative",
                        display: "block",
                        zIndex: "9999",
                        width: "100%",
                        // width: "300px",
                        // maxWidth:"00px",
                        minHeight: "80px",
                        height: "150px",
                        background: "white",
                        border: "2px solid grey",
                        textDecoration: "none",
                      },
                    }}
                  />
                </div>
                {/* /.form__body */}

                <div className="form__foot">
                  <ul className="list--buttons list--buttons-secondary" style={{justifyContent:"center"}}>
                    <li>
                      <button
                        onClick={clear}
                        className="btn btn--outline-solid"
                        type="button"
                      >
                        Clear
                      </button>
                    </li>

                    <li>
                      <button
                        type="button"
                        className="btn btn--success"
                        onClick={async (e) => {
                          e.preventDefault();
                          if(!signCanvas.current || signCanvas.current.isEmpty()){
                            console.log("empty")
                            return
                          }
                          saveSignature(
                            signCanvas.current
                              .getTrimmedCanvas()
                              .toDataURL("image/png")
                          );
                        }}
                      >
                        Save
                      </button>

                    </li>
                  </ul>
                  {/* /.list-/-buttons */}
                </div>
                {/* /.form__foot */}
              </div>
              {/* /.form form-/-main */}
            </div>
            {/* /.popup__inner */}
          </div>
          {/* /.popup */}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      </div>

    </>
  );
};

export default Declaration;
