import React from "react";
import Header from "../../Components/Header";

const Rules = () => {
  return (
    <>
      <Header />
      <div className="main confirmation">
        <div className="main__background">
          <img
            src="assets/images/temp/background-1@2x.jpg"
            alt=""
            width="1920"
            height="857"
            className="hidden-xs"
          />
          <img
            src="assets/images/temp/background-1-mobile@2x.jpg"
            alt=""
            width="1920"
            height="857"
            className="visible-xs-block"
          />
        </div>
        {/* /.main__background */}

        <div className="main__inner">
          <div className="section-alt section-features--alt">
            <div className="shell shell--small">
              <div className="section__inner">
                <header className="section__head">
                  <div className="section__image">
                    <img
                      src="assets/images/svg/prop-bet.svg"
                      alt=""
                      width="340"
                      height="96"
                    />
                  </div>
                  {/* /.section__image */}
                </header>
                {/* /.section__head */}

                <div className="section__body">
                  <div className="form__head">
                    <h1>Contest rules</h1>
                  </div>
                  <div className="form__body">
                    <p>
                      
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rules;
