import React from 'react'

const Footer = () => {
  return (
    <>
    <footer className="footer">
			<div className="shell">
				<ul>
					<li>
						<a href="https://www.olg.ca/en/privacy-and-cookie-policy.html" target="_blank" rel="noreferrer">privacy policy</a>
					</li>
		
					<li>
						<a href="https://www.olg.ca/en/legal/player-agreement.html" target="_blank" rel="noreferrer">terms & conditions</a>
					</li>
				</ul>
		
				<p>Copyright © 2023 Ontario Lottery and Gaming Corporation</p>
		
				<p>© 2023 Maple Leaf Sports & Entertainment Partnership.</p>
			</div>{/* /.shell */}
		</footer>{/* /.footer */}
    </>
  )
}

export default Footer