import { defaultStorageHelper } from '@spry/campaign-client';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { campaignClient, getSessionClient, setSessionClient } from '../../../api';
import Header from '../../../Components/Header'
import Loading from '../../../Components/Loading'
import { emailRegex } from '../../../helpers/regexes';

type FormData = {
    email: string;
    password: string;
  };

const ForgotPassword = () => {
    const [loading,setLoading]=useState(false)
    const navigate = useNavigate()
    const [authError, setAuthError] = useState("");

    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm<FormData>();
    

    const handlePassword =async (data:FormData)=>{
        if(loading){return}
        setLoading(true)
        try {
            const sessionClient = getSessionClient();
            const { authed,sessionKey,error,message } = await sessionClient.call<{
              authed: boolean;
              sessionKey?: string;
              error?:string;
              message?:string;
            }>("sendcode", { email: data.email ,sessionKey:sessionClient.sessionKey});
            if (error && message) {
              setAuthError(message)
            } else {
                setSessionClient(campaignClient.recoverSession(sessionKey));

               navigate('/resetPassword')
            }
          } catch (e: any) {
            setAuthError(e.message);
          }
          setLoading(false)

    }
  return (
     <>
        {loading && <Loading/>}

      <Header />

      <div className="main">
        <div className="main__inner">
          <section className="section-alt section-login">
            <div className="shell shell--small">
              <div className="section__image" style={{ marginBottom: "6rem" }}>
                <img
                  src="assets/images/svg/prop-bet.svg"
                  alt=""
                  width="340"
                  height="96"
                />
              </div>

              <div className="section__body">
                <div className="form form--alt form--login">
                  <form onSubmit={handleSubmit(handlePassword)}>
                    <div className="form__head">
                      <h1>Forgot Password?</h1>
                    </div>
                    {/* /.form__head */}

                    <div className="form__body">
                      <div className="form__row">
                        <label className="form__label form__label--alt">
                          Email
                        </label>

                        <div className="form__controls">
                          <input
                            type="text"
                            className="field "
                            {...register("email", {
                              required: {
                                value: true,
                                message: "Please enter your email.",
                              },
                              pattern: {
                                value: emailRegex,
                                message: "Please enter a valid email.",
                              },
                            })}
                          />
                        </div>
                        {errors.email && (
                        <p className="error-message">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.email.message}{" "}
                        </p>
                      )}
                        {/* /.form__controls */}
                      </div>
                     
                    

                      <button className="link-btn" onClick={()=>navigate('/login')}> Login</button>
                    </div>
                    {/* /.form__body */}

                    <div className="form__foot">
                      <button className="btn btn--success btn--block" type="submit">

                        Reset
                      </button>
                    </div>
                    {authError&& <p className="error-message">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {authError}{" "}
                        </p> }
                    {/* /.form__foot */}
                  </form>
                </div>
                {/* /.form form-/-date */}
              </div>
              {/* /.section__body */}
            </div>
            {/* /.shell-/-small */}
          </section>
          {/* /.section-alt */}
        </div>
        {/* /.main__inner */}
      </div>
    </>
  )
}

export default ForgotPassword