import { defaultStorageHelper } from '@spry/campaign-client'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { getSessionClient, setSessionClient } from '../../../api'
import Header from '../../../Components/Header'
import Loading from '../../../Components/Loading'
import { passwordRegex } from '../../../helpers/regexes'
import './index.css'

type ResetForm ={
    code:string;password:string;confirmPassword:string;
}

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

const ResetPassword = () => {
    const [loading,setLoading]=useState(false)
    const [type, setType] = useState("password");
    const [authError,setAutherror]=useState("")

  const hanldeViewPassword = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };
  const hanldeHidePassword = () => {
    document
      .getElementById("campaignPassword")
      ?.setAttribute("type", "password");
  };
    const navigate =useNavigate()
    const {
        register,
        handleSubmit,
        getValues,
        watch,
        formState: { errors },
      } = useForm<ResetForm>({mode:"onTouched"});

    const handlereset = async(data:ResetForm)=>{

        if(loading){return}
        setLoading(true)
        setAutherror("")
        try{
            const sessionClient = getSessionClient();
            const { authed,sessionKey,error,message } = await sessionClient.call<{
              authed: boolean;
              sessionKey?: string;
              error?:string;
              message?:string;
            }>("forgotpassword", { password: data.password ,sessionKey:sessionClient.sessionKey,code:data.code});
            if (error && message) {
              setAutherror("We couldn’t find an account matching the email and password you entered. Please check your email and password and try again.")
            } else if(sessionKey && authed) {
              defaultStorageHelper.set("auth", "true");
      
              navigate("/dashboard");
            }

        }catch(e){
            window.vex.dialog.alert({
                unsafeMessage: `Sorry, something went wrong. Please try again later.`,
                callback: () => {
                  return;
                },
              });

        }
        
    }
  return (
      <>
    {loading && <Loading/>}

    <Header />

    <div className="main">
      <div className="main__inner">
        <section className="section-alt section-login">
          <div className="shell shell--small">
            <div className="section__image" style={{ marginBottom: "6rem" }}>
              <img
                src="assets/images/svg/prop-bet.svg"
                alt=""
                width="340"
                height="96"
              />
            </div>

            <div className="section__body">
              <div className="form form--alt form--login">
                <form onSubmit={handleSubmit(handlereset)}>
                  <div className="form__head">
                    <h1>Reset Password</h1>
                  </div>
                  {/* /.form__head */}

                  <div className="form__body">

                  <div className="form__row">
                      <label
                        htmlFor="field-password"
                        className="form__label form__label--alt"
                      >
                        Code
                      </label>

                      <div className="form__controls ">
                        <input
                          type="number"
                          className="field"
                          {...register("code", {
                            required: {
                              value: true,
                              message: "Please enter the code your received.",
                            },
                          })}
                        />
                      </div>
                      {errors.code && (
                        <p className="error-message">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.code.message}{" "}
                        </p>
                      )}
                      {/* /.form__controls */}
                    </div>
                   
                   
                    <div className="form__row passwordControl">
                      <label
                        htmlFor="field-password"
                        className="form__label form__label--alt"
                      >
                        New Password
                      </label>

                      <div className="form__controls">
                      <button
                            className="viewPass"
                            onClick={hanldeViewPassword}
                            onMouseUp={hanldeHidePassword}
                            disabled={
                              getValues().password?.length > 0
                                ? false
                                : true
                            }
                          >
                            <img
                              src="assets/images/svg/ico-view.png"
                              alt="View Password"
                            />
                          </button>
                      <input
                            type={type}
                            id="campaignPassword"
                            className="field"
                            {...register("password", {
                              required: {
                                value: true,
                                message: "Please enter a password.",
                              },
                              pattern: {
                                value: passwordRegex,
                                message: "Please enter a valid password.",
                              },
                            })}
                            placeholder=""
                          />
                          <small>
                            (password must be between 8 to 20 characters, must contain at least 1 uppercase, 1 lowercase and 1 number.)
                          </small>
                        </div>
                        {errors.password && (
                        <p className="error-message">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.password.message}{" "}
                        </p>
                      )}
                        {/* /.form__controls */}
                      </div>
                    {/* /.form__row */}
                    <div className="form__row">
                        <label
                          htmlFor="field-confirm-password"
                          className="form__label"
                        >
                          * Confirm password
                        </label>

                        <div className="form__controls">
                          <input
                            type="password"
                            className="field"
                            placeholder=""
                            {...register("confirmPassword", {
                              required: {
                                value: true,
                                message: "Please confirm your password.",
                              },
                              validate: (val: string) => {
                                if (watch("password") != val) {
                                  return "Please make sure your passwords match.";
                                }
                              },
                            })}
                          />
                        </div>
                        {/* /.form__controls */}
                      </div>
                      {errors.confirmPassword && (
                        <p className="error-message">
                          <i className="fas fa-exclamation-circle" />{" "}
                          {errors.confirmPassword.message}{" "}
                        </p>
                      )}
                      {/* /.form__row */}

                    <button className="link-btn" onClick={()=>navigate('/login')}> Login?</button>
                  </div>
                  {/* /.form__body */}

                  <div className="form__foot">
                    <button className="btn btn--success btn--block">
                      Reset
                    </button>
                  </div>
                  {authError&& <p className="error-message">
                        <i className="fas fa-exclamation-circle" />{" "}
                        {authError}{" "}
                      </p> }
                  {/* /.form__foot */}
                </form>
              </div>
              {/* /.form form-/-date */}
            </div>
            {/* /.section__body */}
          </div>
          {/* /.shell-/-small */}
        </section>
        {/* /.section-alt */}
      </div>
      {/* /.main__inner */}
    </div>
    </>
  )
}

export default ResetPassword