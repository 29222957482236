import { defaultStorageHelper } from "@spry/campaign-client";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { campaignClient, getSessionClient } from "../../../api";
import Header from "../../../Components/Header";
import { format } from "date-fns";
import "./index.css";
import Loading from "../../../Components/Loading";
export type Question = {
  qid?: string;
  category: string;
  question: string;
  options: QuestionOption[];
  validAnswer: string;
  type: string;
  answer?: string;
};
export type QuestionOption = {
  optionId?: string;
  answer: string;
  odds: string;
};
export type PropbetsForm = {
  proline: string[];
  fun: string[];
};
export type Matchup = {
  league: string;
  homeTeam: string;
  awayTeam: string;
  startDate: string;
  startTime: string;
};

export type SubmittedContest = {
  sessionKey: string;
  props: { question: string; answer: string; result: string }[];
  location: { lat: string; long: string };
  prolineprops: string[];
  contestWeek: string;
};
declare const window: Window &
  typeof globalThis & {
    vex: any;
  };
const History = () => {
  const [loading, setLoading] = useState(false);
  const [submittedContests, setSubmittedContests] = useState<any[]>();
  const navigate = useNavigate();
  const [keys, setKeys] = useState<boolean[]>();
  const [currentprops, setCurrentProps] = useState<{questions:Question[],contestStatus:number}>();

  const getplaybets = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const { sessionKey } = await getSessionClient();
      const { contests } = await campaignClient.call<{ contests: any[] }>(
        "getbets",
        { sessionKey }
      );
        setSubmittedContests(contests);
        setKeys(contests.map((c, index) => false));
      
     
    } catch (e) {}
    setLoading(false);
  };
  useEffect(() => {
    getplaybets();
  }, []);
  // console.log(submittedContests,currentprops)
  const handleSelection = async (selectedWeek: string) => {
    if (!selectedWeek) {
      return;
    }
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const { sessionKey } = await getSessionClient();
      const { questions,contestStatus } = await campaignClient.call<{ questions: any ,contestStatus:number}>(
        "getbets",
        { sessionKey, contestWeek: selectedWeek }
      );
      if (questions.length > 0) {
        setCurrentProps({questions,contestStatus});
      }
    } catch (e) {}
    setLoading(false);
  };

  return (
    <>
      {loading && <Loading />}
      <Header />
      <div className="main">
        <div className="main__background">
          <img
            src="assets/images/temp/background-1@2x.jpg"
            alt=""
            width="1920"
            height="857"
            className="hidden-xs"
          />
          <img
            src="assets/images/temp/background-1-mobile@2x.jpg"
            alt=""
            width="1920"
            height="857"
            className="visible-xs-block"
          />
        </div>
        {/* /.main__background */}

        <div className="main__inner">
          <div className="section-alt section-features section-accordion">
            <div className="shell shell--small">
              <div className="section__inner">
                <header className="section__head">
                  <div className="section__image">
                    <img
                      src="assets/images/svg/prop-bet.svg"
                      alt=""
                      width="340"
                      height="96"
                    />
                  </div>
                  {/* /.section__image */}
                </header>
                {/* /.section__head */}

                <div className="section__body">
                  <h1>Your past picks</h1>
                  {submittedContests && submittedContests?.length > 0 ?
                  <p>
                    See your picks for past weeks and how many ballots you’ve
                    earned.
                  </p>:

                  <p>
                    No picks for past weeks are submitted.
                  </p>}
                  {submittedContests && submittedContests.length>0 &&
                      keys &&
                  <div className="accordion js-accordion section__accordion">
                   {
                      submittedContests.map((contest, index) => (
                        <div
                          className={
                            keys[index]
                              ? "accordion__section is-active"
                              : "accordion__section"
                          }
                          key={index}
                        >
                          <div
                            className="accordion__head"
                            onClick={async () => {
                              let resetKeys =  keys.map(key=>false)
                              resetKeys[index] = !keys[index];
                              setKeys(resetKeys);
                              if (resetKeys[index]) {
                                handleSelection(contest.contestWeek);
                              }
                            }}
                          >
                            <h4>
                              {contest.contestWeek.split("#")[0]}
                              {contest.contestWeek.split("#")[1]}{" "}
                              <span>
                                {" (" +
                                  format(
                                    new Date(contest.contestWeek.split("#")[2].replace('-','/')),
                                    "MMM dd"
                                  ) +
                                  " - " +
                                  format(
                                    new Date(contest.contestWeek.split("#")[3].replace('-','/')),
                                    "MMM dd"
                                  ) +
                                  ")"}{" "}
                              </span>
                             
                             
                            </h4>
                            {/* {contest.status <4 &&<p>Results are still pending....</p>} */}
                          </div>
                          {/* /.accordion__head */}

                          <div
                            className={
                              keys[index]
                                ? "accordion__body show"
                                : "accordion__body noshow"
                            }
                          >
                            <div className="table-primary">
                              <table>
                                <thead>
                                {currentprops && currentprops?.contestStatus <4 ? <tr>
                                  <th>Prop</th>

                                    <th>Your Answer</th>

                                    <th>Result</th>
                                  </tr>:
                                  <tr>
                                    <th>Prop</th>

                                    <th>Result</th>

                                    <th>&nbsp;</th>
                                  </tr>}
                                </thead>

                                <tbody>
                                  {currentprops?.questions &&
                                    currentprops.questions.map((question) => (
                                      <tr key={question.qid}>
                                        <td>{question.question}</td>

                                        <td>{currentprops.contestStatus < 4 ? question.answer: question.options.find(o=>o.optionId === question.validAnswer)?.answer}</td>

                                        <td>
                                          {question.validAnswer ? 
                                          (question.validAnswer === question.options.find(o=>o.answer === question.answer)?.optionId
                                           ? 
                                            <i className="ico-check-green">
                                              <img
                                                src="assets/images/svg/check-green.svg"
                                                alt=""
                                                width="20"
                                                height="20"
                                              />
                                            </i>
                                           : 
                                            <i className="ico-close-circle">
                                              <img
                                                src="assets/images/svg/close-circle.svg"
                                                alt=""
                                                width="20"
                                                height="20"
                                              />
                                            </i>
                                          ): "pending"}
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>

                                <tfoot>
                                  <tr>
                                    <td colSpan={3}>
                                      <span>
                                        {
                                          currentprops?.questions?.filter(
                                            (q) => q.answer === q.options.find(o=>o.optionId === q.validAnswer)?.answer
                                          ).length
                                        }
                                      </span>
                                      Ballots earned
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                            {/* /.table */}
                          </div>
                          {/* /.accordion__body */}
                        </div>
                      ))}
                  </div>}
                  {/* /.accordion js-accordion */}
                </div>
                {/* /.section__body */}
              </div>
              {/* /.section__inner */}
            </div>
            {/* /.shell */}
          </div>
          {/* /.section-features */}
        </div>
        {/* /.main__inner */}
      </div>
      {/* /.main */}
    </>
  );
};

export default History;
