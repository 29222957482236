import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import Header from "../../../Components/Header";
import { defaultStorageHelper } from "@spry/campaign-client";
import { campaignClient, getSessionClient } from "../../../api";
import { isAfter } from "date-fns";
import { useFieldArray, useForm } from "react-hook-form";
import Loading from "../../../Components/Loading";
import {endOfWeek, startOfDay } from 'date-fns';
import { isLocationWithinBoundary } from "../../../helpers/utils";
import './index.css'



export type Question = {
  qid: string
  category: string
  question: string
  options: QuestionOption[]
  validAnswer: string
  type: string
  answer?:string
};
export type QuestionOption = {
  optionId?: string
    answer: string
    odds: string
};
export type PropbetsForm = {
  contestWeek: string
  startDate: string
  endDate: string
  matchups: Matchup[] 
  weekNumber: number
  proline: Question[];
  fun: Question[];
};
export type Matchup = {
  league: string
  homeTeam: string
  awayTeam: string
  startDate: string
  startTime: string
}

 export type Contest = {
  contestWeek: string
  startDate: string
  endDate: string
  matchups: Matchup[] 
  questions: Question[]
  weekNumber: number
}
declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

const Propbets = () => {
  const [loading, setLoading] = useState(false);
  // const [formQuestions, setFormQuestions] = useState<PropbetsForm>();
  const [contest, setContest] = useState<Contest>();
  const navigate = useNavigate();


  const {
    register,
    handleSubmit,
    reset,control,
    formState: { errors },
  } = useForm<PropbetsForm>({ mode: "onTouched" });

  const {
    fields: prolinefields,
  } = useFieldArray({
    control,
    name: "proline",
  });
  const {
    fields: funfields,
  } = useFieldArray({
    control,
    name: "fun",
  });

// console.log(prolinefields)

  const getplaybets = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const { sessionKey } = await getSessionClient();
      const { contest, currentweekSubmission } = await campaignClient.call<{
        contest: any;
        currentweekSubmission: boolean;
        firstName:string
      }>("getmatchups", { sessionKey });
    

      let currentWeekFreezDate = startOfDay(endOfWeek(new Date(contest[0].startDate)))
      // console.log(currentWeekFreezDate)
     
    if(currentweekSubmission){
          window.vex.dialog.alert({
            unsafeMessage: `Your picks are locked in for the week, Good luck! Check your history each Monday to see how you did.`,
            callback: () => {
              navigate('/')
              return;
            },
          });
  
    }
    else
        if( isAfter(new Date(),currentWeekFreezDate)){
          window.vex.dialog.alert({
            unsafeMessage: `The bets are locked in for the week, Come again next Monday to play next week.`,
            callback: () => {
              navigate('/')
              return;
            },
          });
  
        }

      if (contest[0]) {
        const currentWeekContest = contest[0]
        const currentContestquestions = currentWeekContest.questions

        
        let prolineq =  currentContestquestions
        .filter(
          (question: Question) => question.category === "proline"
        )
                let funq = currentWeekContest.questions.filter(
          (question: Question) => question.category === "fun"
        )
        setContest(currentWeekContest);
       
        // setFormQuestions({...contest[0],fun:funq,proline:prolineq});
        reset({...contest[0],fun:funq,proline:prolineq})
        }
        else{
          window.vex.dialog.alert({
            unsafeMessage: `Sorry, No Contest questons added for this week.`,
            callback: () => {
              // navigate('/')
              return;
            },
          });
        }
      
    } catch (e) {}
    setLoading(false)

  };let lat = defaultStorageHelper.get("latitude");
  let long = defaultStorageHelper.get("longitude");

const checkBoundary = ( ) => {
  
  if(!lat || !long || !isLocationWithinBoundary(Number(lat), Number(long))){
    window.vex.dialog.alert({
      unsafeMessage: `Sorry! You are out of boundary!`,
      callback: () => {
      // navigate('/')
     getplaybets();
        return;
      },
    });
  }
  else{
    getplaybets();
  }
}

  useEffect(() => {
    window.scrollTo(0,0)
    checkBoundary()
  }, [lat,long]);

  const handlepropbets = async (data: PropbetsForm) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const { sessionKey } = await getSessionClient();
      const { contestWeek, error, message } = await campaignClient.call<{
        contestWeek?: string;
        error?: string;
        message?: string;
      }>("submitbets", {
        sessionKey,
        contestWeek: contest?.contestWeek,
        prolineProps: data.proline.map(q=>{return {qid:q.qid,optionId:q.answer,answer:q.options.find(o=>o.optionId===q.answer)?.answer}}),
        funProps: data.fun.map(q=>{return {qid:q.qid,optionId:q.answer,answer:q.options.find(o=>o.optionId===q.answer)?.answer}}),
        location: {
          lat: defaultStorageHelper.get("latitude") || "",
          long: defaultStorageHelper.get("longitude") || "",
        },
      });

      if (contestWeek) {
        navigate("/confirmation");
      } else if (error) {
        window.vex.dialog.alert({
          unsafeMessage: `${message}`,
          callback: () => {
            return;
          },
        });
      }
    } catch (e) {}

    setLoading(false)
  };

  return (
    <>
        {loading && <Loading/>}

      <Header />
      <div className="main">
        <div className="main__background">
          <img
            src="assets/images/temp/background-1@2x.jpg"
            alt=""
            width="1920"
            height="857"
            className="hidden-xs"
          />
          <img
            src="assets/images/temp/background-1-mobile@2x.jpg"
            alt=""
            width="1920"
            height="857"
            className="visible-xs-block"
          />
        </div>
        {/* /.main__background */}

        <div className="main__inner">
          <div className="section-alt section-features section-features--alt">
            <div className="shell shell--small">
              <div className="section__inner">
                <header className="section__head">
                  <div className="section__image">
                    <img
                      src="assets/images/svg/prop-bet.svg"
                      alt=""
                      width="340"
                      height="96"
                    />
                  </div>
                  {/* /.section__image */}
                </header>
                {/* /.section__head */}

                <div className="section__body">
                  <div className="form form--primary">
                    <form onSubmit={handleSubmit(handlepropbets)}>
                      <div className="form__head">
                        <h1>Make your picks!</h1>

                        <p>
                          Select between the two options displayed below each
                          prop bet. Odds are subject to change on PROLINE+ and
                          PROLINE.com.
                        </p>
                      </div>
                      {/* /.form__head */}

                      <div className="form__body">
                        <div className="form__group">
                          <div className="info form__info">
                            <div className="info__inner">
                              <h1>PROLINE+ PROPS</h1>

                              <i className="ico-info">
                                <img
                                  src="assets/images/svg/info.svg"
                                  alt=""
                                  width="18"
                                  height="18"
                                />
                                
                              </i>
                              <div className="tooltiptext"><i className="fas fa-exclamation-circle" />{" "}Keep an eye on your budget with a Sports Spend Limit in your My PlaySmart Tools.</div>
                            </div>
                            {/* /.info__inner */}
                          </div>
                          {/* /.info */}

                          {prolinefields.map((field, index) => {
                            return (
                              <div className="form__group-inner" key={index}>
                                <h6>{field.question}</h6>

                                <div className="radios">
                                  <ul>
                                    {field.options.map((option, index1) => (
                                      <li key={`pro${index}${index1+1}`}> 
                                        <div className="radio">
                                          <input
                                            type="radio"
                                            {...register(
                                              `proline.${index}.answer` as const,
                                              {
                                                required: {
                                                  value: true,
                                                  message: `Please select answer.`,
                                                },
                                              }
                                            )}
                                            id={`optionpro+${index}${index1}`}
                                            value={option.optionId}
                                          />

                                          <label htmlFor={`optionpro+${index}${index1}`}>
                                            {option.answer}

                                            <span>{option.odds}</span>
                                          </label>
                                        </div>
                                        {/* /.radio */}
                                      </li>
                                      
                                    ))}
                                  </ul>
                                  {errors?.proline && errors.proline[index] && <p className="error-message">
                          <i className="fas fa-exclamation-circle" />{" "}
                         Please select an answer
                        </p>}

                                </div>
                              </div>
                            );
                          })}
                          {!contest && <p style={{    marginTop: "5rem",
    marginBottom: "5rem",
    color: "#40A83C",
    padding: "2rem 2.7rem",
}}><i className="fas fa-exclamation-circle" style={{color: "#1a7024"}}></i> There are no contest questons to display.</p>}
                        </div>
                        {/* /.form__group */}
                        <div className="form__group">
                          <div className="info form__info">
                            <div className="info__inner">
                              <h1>fun PROPS</h1>

                              <i className="ico-info">
                                <img
                                  src="assets/images/svg/info.svg"
                                  alt=""
                                  width="18"
                                  height="18"
                                />
                              </i>
                              <div className="tooltiptext"><i className="fas fa-exclamation-circle" />{" "}Keep an eye on your budget with a Sports Spend Limit in your My PlaySmart Tools.</div>

                            </div>
                            {/* /.info__inner */}
                          </div>
                          {/* /.info */}

                          {funfields.map((field, index) => {
                            return (
                              <div className="form__group-inner" key={index}>
                                <h6>{field.question}</h6>

                                <div className="radios">
                                  <ul>
                                    {field.options.map((option, index1) => (
                                      <li key={`fun${index}${index1+1}`}>
                                        <div className="radio">
                                          <input
                                            type="radio"
                                            {...register(`fun.${index}.answer`, {
                                              required: true,
                                            })}
                                            id={`funoption${index}${index1}`}
                                            value={option.optionId}
                                          />

                                          <label htmlFor={`funoption${index}${index1}`}>
                                            {option.answer}

                                            <span>{option.odds}</span>
                                          </label>
                                        </div>
                                        {/* /.radio */}
                                      </li>
                                    ))}
                                  </ul>
                                  {errors?.fun && errors.fun[index] && <p className="error-message">
                          <i className="fas fa-exclamation-circle" />{" "}
                         Please select an answer
                        </p>}
                                </div>
                              </div>
                            );
                          })}
                           {!contest && <p style={{    marginTop: "5rem",
    marginBottom: "5rem",
    color: "#40A83C",
    padding: "2rem 2.7rem",
}}><i className="fas fa-exclamation-circle" style={{color: "#1a7024"}}></i> There are no contest questons to display.</p>}
                        </div>
                        {/* /.form__group */}
                      </div>
                      {/* /.form__body */}

                      <div className="form__foot">
                        <button
                          type="submit"
                          className="btn btn--success btn--block"
                        >
                          Submit my picks
                        </button>
                      </div>
                      {/* /.form__foot */}
                    </form>
                  </div>
                  {/* /.form form-/-picks */}
                </div>
                {/* /.section__body */}
              </div>
              {/* /.section__inner */}
            </div>
            {/* /.shell */}
          </div>
          {/* /.section-features */}
        </div>
        {/* /.main__inner */}
      </div>
      {/* /.main */}
    </>
  );
};

export default Propbets;
