import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import { handleSessionKey } from "./helpers/querystring"
import handleWwwRedirect from './helpers/wwwRedirect/handleWwwRedirect'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import App from './App'
import Pages from './Pages'

handleSessionKey()
handleWwwRedirect()

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
        <App/>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
