import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import Footer from '../Components/Footer'

export default function Pages() {
    const { t } = useTranslation()

    return (
<div className="wrapper wrapper--solid">        
            <section >
                <Outlet />
            </section>
            <Footer/>

        </div>
    )
}