import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import { emailRegex } from "../../../helpers/regexes"
import { campaignClient, getSessionClient, setSessionClient } from "../../../api"
import { useState } from "react"
import Header from "../../../Components/Header"

type ReturningFormData = {
    email: string
}

export default function Returning() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { register, handleSubmit, formState: { errors }} = useForm<ReturningFormData>()

    const [submitting, setSubmitting] = useState(false)
    const [noSuchEmail, setNoSuchEmail] = useState(false)
    const [submissionError, setSubmissionError] = useState(false)

    async function handleReturningUser(data: ReturningFormData) {
        if (submitting) { return }
        setSubmitting(true)
        setNoSuchEmail(false)
        setSubmissionError(false)
        try {
            const { sessionKey } = await getSessionClient()
                .createParticipantBuilder()
                .setEmail(data.email)
                .call<{ sessionKey?: string }>("returning")
            if (sessionKey) {
                setSessionClient(campaignClient.recoverSession(sessionKey))
                navigate("/stuff-to-do")
            }
            else {
                setNoSuchEmail(true)
            }
        }
        catch {
            setSubmissionError(true)            
        }
        setSubmitting(false)
    }

    return (
        <>
        <Header/>
        <div className="main">
			<div className="main__inner">
				<section className="section-alt section-login">
					<div className="shell shell--small">

						<div className="section__body">
							<div className="form form--alt form--login">
								<form action="?" method="post">
									<div className="form__head">
										<h1>login</h1>
									</div>{/* /.form__head */}

									<div className="form__body">
										<div className="form__row">
											<label htmlFor="field-email" className="form__label form__label--alt">Email</label>

											<div className="form__controls">
												<input type="text" className="field field--light" name="field-email" id="field-email" value="johnsmith@email.com" placeholder="" />
											</div>{/* /.form__controls */}
										</div>{/* /.form__row */}

										<div className="form__row">
											<label htmlFor="field-password" className="form__label form__label--alt">Password</label>

											<div className="form__controls">
												<input type="password" className="field field--light" name="field-password" id="field-password" value="•••••••••••••••" placeholder="" />
											</div>{/* /.form__controls */}
										</div>{/* /.form__row */}

										<a href="#">Forgot password?</a>
									</div>{/* /.form__body */}

									<div className="form__foot">
										<button className="btn btn--success btn--block">Login</button>
									</div>{/* /.form__foot */}
								</form>
							</div>{/* /.form form-/-date */}
						</div>{/* /.section__body */}
					</div>{/* /.shell-/-small */}
				</section>{/* /.section-alt */}
			</div>{/* /.main__inner */}
		</div>{/* /.main */}

        </>
    )
}